import { isNumber } from 'lodash';

export class CurrencyUtils {
    public static formatCurrency(amount: number): string {
        if (!amount || !isNumber(amount)) {
            return '0.00';
        }
        return amount.toFixed(2);
    }
}
