import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { MeterInfoBaseComponent } from '@app/energy/components/meter-info-base/meter-info-base.component';
import { EnergyMeterReadingAssetsInputComponent } from '@app/form/components/energy-meter-reading-assets-input/energy-meter-reading-assets-input.component';
import { FileInput } from '@app/form/interfaces/file-input';
import { fileValidator } from '@app/form/validators/file-type.validator';
import { Move } from '@app/move/interfaces/move';
import { Movers } from '@app/move/interfaces/movers';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveUtils } from '@app/move/state/move.utils';
import { NotificationLabel } from '@app/notification/enums/notification-label.enum';
import { FileModalData } from '@app/real-estate-agent/interfaces/file-modal-data';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { MobileEnergyDocumentAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-energy-document-asset-detail/mobile-energy-document-asset-detail.component';
import { MobileMeterInfoElectricityComponent } from '@app/real-estate-agent/modals/mobile-meter-info-electricity/mobile-meter-info-electricity.component';
import { MobileMeterInfoGasComponent } from '@app/real-estate-agent/modals/mobile-meter-info-gas/mobile-meter-info-gas.component';
import { MobileMeterReadingAssetDetailComponent } from '@app/real-estate-agent/modals/mobile-meter-reading-asset-detail/mobile-meter-reading-asset-detail.component';
import {
    energyAssetSize,
    mobileEnergyMetersForm,
    mobileEnergyMetersTableColumns,
} from '@app/real-estate-agent/modals/mobile-move-energy-meters/mobile-move-energy-meters.constants';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { DbUtils, HttpUtils, MoveTransactionType } from '@smooved/core';
import { MobileModalComponent, ModalSandbox, NotificationSandbox, UiContext } from '@smooved/ui';
import { concat } from 'rxjs';
import { last, takeUntil } from 'rxjs/operators';
import { MobileMoveEnergyMetersFormData } from './mobile-move-energy-meters.interfaces';

@Component({
    selector: 'app-mobile-move-energy-meters-modal',
    templateUrl: 'mobile-move-energy-meters.component.html',
    styleUrls: ['./mobile-move-energy-meters.component.scss'],
})
export class MobileMoveEnergyMetersComponent extends MeterInfoBaseComponent implements OnInit {
    @ViewChild(MobileModalComponent) public detail: MobileModalComponent;
    @ViewChild(EnergyMeterReadingAssetsInputComponent)
    public energyMeterReadingAssetsInputComponent: EnergyMeterReadingAssetsInputComponent;

    @Output() public success = new EventEmitter<Move>();

    public readonly uiContext = UiContext;
    public readonly columns = mobileEnergyMetersTableColumns;
    public readonly moveTransactionTypes = MoveTransactionType;
    public readonly mobileEnergyMetersForm = mobileEnergyMetersForm;

    public moveTransactionType: MoveTransactionType;
    public movers: Movers;
    public dataSource: any[] = [];
    public isDisabled = false;
    public form: UntypedFormGroup;

    protected meterReadingsTakeOverControl;

    constructor(
        protected moveSandbox: MoveSandbox,
        private uiSandbox: AppUiSandbox,
        private notificationSandbox: NotificationSandbox,
        private fb: UntypedFormBuilder,
        private modalSandbox: ModalSandbox,
        private translateService: TranslateService,
        public authenticationSandbox: AuthenticationSandbox,
        @Inject(MAT_DIALOG_DATA) public data: ModalDataMove
    ) {
        super(moveSandbox, authenticationSandbox);
    }

    private formFactory(): FormGroup {
        return this.fb.group({
            [mobileEnergyMetersForm.MeterReadingAssets]: [
                null,
                [
                    fileValidator(
                        this.energyMeterReadingAssetsInputComponent?.files,
                        this.energyMeterReadingAssetsInputComponent?.acceptTypes.split(', '),
                        energyAssetSize
                    ),
                ],
            ],
            [mobileEnergyMetersForm.EnergyType]: this.getInitialEnergyType(),
            [mobileEnergyMetersForm.MovingDate]: this.data.move.movingDate,
            [mobileEnergyMetersForm.MeterReadingsTakeover]: false,
        });
    }

    public ngOnInit(): void {
        this.form = this.formFactory();
        this.meterReadingsTakeOverControl = this.form.get(mobileEnergyMetersForm.MeterReadingsTakeover);
        super.ngOnInit();

        this.move$.pipe(takeUntil(this.destroy$)).subscribe(this.handleMoveChanged);

        this.checkTakeOverInformation();

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.checkTakeOverInformation());
    }

    private handleMoveChanged = (move: Move): void => {
        this.dataSource = this.getDataSource(move);

        this.moveTransactionType = MoveUtils.getMoveTransactionType(move);
        this.movers = MoveUtils.getMovers(move);

        this.isDisabled =
            this.data.readOnly ||
            !!move?.energyDocumentsMovingAddressByAdmin ||
            !!move?.energyMeterTransferDocumentsMovingAddressWithoutContract ||
            !!move?.moveStates.metersProcessedByAdmin ||
            !!move.energyReadingsConfirmedByRealEstateAgent;

        this.isDisabled ? this.form.disable() : this.form.enable();
    };

    public isDirty(): boolean {
        return this.form.dirty;
    }

    public onEnergyMeterReadingDetail(file: FileInput): void {
        const data: FileModalData = {
            moveId: DbUtils.getStringId(this.data.move),
            file,
            readOnly: this.data.readOnly,
        };
        this.modalSandbox
            .openMobileModal(MobileMeterReadingAssetDetailComponent, { data })
            .afterClosed()
            .subscribe((move) => this.handleAssetDetailModalClose(move));
    }

    public onEnergyDocumentDetail(file: FileInput): void {
        const data: FileModalData = {
            moveId: DbUtils.getStringId(this.data.move),
            file,
            readOnly: this.data.readOnly,
        };
        this.modalSandbox
            .openMobileModal(MobileEnergyDocumentAssetDetailComponent, { data })
            .afterClosed()
            .subscribe((move) => this.handleAssetDetailModalClose(move));
    }

    protected hasAssets(): boolean {
        return (
            !!this.energyMeterReadingAssetsInputComponent?.files.length ||
            !!this.data.move.energyMeterReadingAssets?.length ||
            !!this.data.move.energyDocumentAssets?.length
        );
    }

    public onEnergyMeterReadingAssetPreview(): void {
        this.form
            .get(mobileEnergyMetersForm.MeterReadingAssets)
            .setValidators(
                fileValidator(
                    this.energyMeterReadingAssetsInputComponent?.files,
                    this.energyMeterReadingAssetsInputComponent?.acceptTypes.split(', '),
                    energyAssetSize
                )
            );
        this.form.get(mobileEnergyMetersForm.MeterReadingAssets).updateValueAndValidity();
    }

    public submit(): void {
        this.takeoverInformationConsentComponent?.markAllAsTouched();
        if (!this.form.valid) return;
        if (!this.takeoverInformationConsentComponent) {
            this.patchMove();
        } else {
            this.takeoverInformationConsentComponent.persistInformation().subscribe(() => {
                this.patchMove();
            });
        }
    }

    protected dataTakeOverInformationFactory(): Partial<Move> {
        return this.getPatchData();
    }

    protected getPatchData(): Partial<Move> {
        const { movingDate } = this.form.value as MobileMoveEnergyMetersFormData;
        return {
            ...this.data.move,
            ...super.getPatchData(),
            movingDate,
        };
    }

    private patchMove(): void {
        const patch: Move = this.getPatchData();
        this.uiSandbox.showLoadingOverlay();
        this.moveSandbox.moveOnce$.subscribe((move: Move) => {
            this.moveSandbox.patchProperty(
                '',
                patch,
                true,
                (move: Move) => {
                    const httpCalls = [];
                    if (this.energyMeterReadingAssetsInputComponent.files?.length) {
                        const formData = HttpUtils.addFiles(this.energyMeterReadingAssetsInputComponent.files);
                        httpCalls.push(this.moveSandbox.uploadEnergyMeterReadingAsset(this.data.move._id, formData));
                    }
                    if (httpCalls.length) {
                        this.uiSandbox.showLoadingOverlay();
                        concat(...httpCalls)
                            .pipe(last())
                            .subscribe(
                                (move: Move) => {
                                    this.uiSandbox.hideLoadingOverlay();
                                    this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
                                    this.updateMove(move, true);
                                    this.form.reset({
                                        [mobileEnergyMetersForm.MovingDate]: move.movingDate,
                                    });
                                    this.energyMeterReadingAssetsInputComponent.files = [];
                                    this.energyMeterReadingAssetsInputComponent.previews = null;
                                },
                                (_) => {
                                    this.uiSandbox.hideLoadingOverlay();
                                    this.handleModalCloseMobile(move);
                                }
                            );
                    } else {
                        this.uiSandbox.hideLoadingOverlay();
                        this.handleModalCloseMobile(move);
                    }
                },
                false,
                move,
                true
            );
        });
    }

    private handleAssetDetailModalClose(move: Move): void {
        if (!move) return;
        this.updateMove(move);
    }

    private handleModalCloseMobile(updatedMove: Move): void {
        if (updatedMove) {
            this.data.move = updatedMove;
            this.updateMove(updatedMove, true);
            this.notificationSandbox.success(NotificationLabel.MovePatchSuccess);
            this.checkTakeOverInformation();
        }
    }

    private getDataSource(move: Move): any[] {
        const rows = [];
        const data = {
            move,
            patchedSubject: this.data.patchedSubject,
            readOnly: this.isDisabled,
        };

        if (MoveUtils.showMeterReadingElectricity(move)) {
            rows.push({
                label: this.translateService.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.METER_READINGS.ELECTRICITY'),
                navigate: () => {
                    this.modalSandbox.openModal(
                        MobileMeterInfoElectricityComponent,
                        { data: { move, patchedSubject: this.patchedSubject, readOnly: this.isDisabled } },
                        (move) => this.handleModalCloseMobile(move),
                        null,
                        null,
                        null
                    );
                },
            });
        }

        if (MoveUtils.showMeterReadingGas(move)) {
            rows.push({
                label: this.translateService.instant('REAL_ESTATE_AGENT.DASHBOARD.DETAIL.METER_READINGS.GAS'),
                navigate: () => {
                    this.modalSandbox.openModal(
                        MobileMeterInfoGasComponent,
                        { data: { move, patchedSubject: this.patchedSubject, readOnly: this.isDisabled } },
                        (move) => this.handleModalCloseMobile(move),
                        null,
                        null,
                        null
                    );
                },
            });
        }
        return rows;
    }
}
