<app-loading-container [loading]="loading">
    <form class="u-flex-column u-flex-align-items-start u-w100p u-margin-bottom-double" [formGroup]="form">
        <app-check-input
            [option]="optionElectricity"
            [custom]="false"
            width="auto"
            [attr.data-testid]="'current-supplier-has-electricity-input'"
            [formControl]="hasElectricity"
        ></app-check-input>
        <ng-container [formGroup]="energyStopForm">
            <div class="u-form-section u-flex-column u-w100p u-margin-bottom-double" *ngIf="hasElectricity.value">
                <smvd-ui-text-input
                    id="currentSupplierElectricity"
                    [label]="prefixLabels + 'ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | translate"
                    [hasMargin]="false"
                    [hasMarginDouble]="true"
                    [formControlName]="formControlNames.electricityCurrentSupplier"
                    [attr.data-testid]="'current-supplier-electricity-input'"
                ></smvd-ui-text-input>
            </div>

            <app-check-input
                [option]="optionGas"
                class="u-margin-bottom"
                [hasMargin]="false"
                [hasMarginDouble]="false"
                [custom]="false"
                width="auto"
                [formControl]="hasGas"
                [attr.data-testid]="'current-supplier-has-gas-input'"
            >
            </app-check-input>
            <div class="u-form-section u-flex-column u-w100p" *ngIf="hasGas.value">
                <app-check-input
                    *ngIf="hasElectricity.value"
                    [option]="optionSameSupplier"
                    [class.u-margin-bottom]="!hasSameSupplier.value"
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [custom]="false"
                    width="auto"
                    [formControlName]="formControlNames.hasSameSupplier"
                    [attr.data-testid]="'current-supplier-gas-same-as-electricity-input'"
                >
                </app-check-input>

                <ng-container *ngIf="!hasSameSupplier.value">
                    <smvd-ui-text-input
                        id="currentSupplierGas"
                        [label]="prefixLabels + 'ENERGY_TRANSFER.CURRENT_SUPPLIER.LABEL' | translate"
                        [hasMargin]="false"
                        [hasMarginDouble]="true"
                        [formControlName]="formControlNames.gasCurrentSupplier"
                        [attr.data-testid]="'current-supplier-gas-input'"
                    ></smvd-ui-text-input>
                </ng-container>
            </div>
        </ng-container>
    </form>
</app-loading-container>
