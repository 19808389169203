import { environment } from '@environments/environment';
import { baseUri } from '@shared/order/constants/uri.constants';

export const endpointUri = `${environment.apiUri}/${baseUri}`;
export const idUri = `${endpointUri}/:id`;
export const giftsIdUri = `${endpointUri}/gifts/:id`;
export const moveByOrderUri = `${endpointUri}/:id/move`;
export const giftUri = `${endpointUri}/gift`;
export const mortgagesUri = `${endpointUri}/mortgages`;
export const orderMortgageUri = `${endpointUri}/order-mortgage`;
export const orderMortgageIdUri = `${orderMortgageUri}/:id`;
export const createMessageUri = `${idUri}/message`;
export const orderEnergySupplier = `${endpointUri}/:id/energy-supplier`;
export const orderEnergySupplierStatus = `${endpointUri}/:id/energy-supplier-status`;
