smvd-app-takeover-information-consent {
    smvd-ui-card {
        &.has-error {
            border-color: var(--color-error) !important;
        }

        &:not(.has-context-default) {
            padding: 1rem;
        }

        &.has-context-default {
            padding: 0;
            border: none;
        }

        &.has-context-muted {
            app-check-input .__label {
                color: var(--color-text) !important; // use darker color in card, otherwise this looks like a disabled checkbox
            }
        }

    }

    mat-error {
        margin-left: 1.5rem; // width checkbox and margin
    }
}
