import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationSandbox } from '@app/authentication/sandboxes/authentication.sandbox';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ModalDataMove } from '@app/real-estate-agent/interfaces/modal-data-move.interfaces';
import { ObjectUtils, RxjsComponent } from '@smooved/core';
import { ModalSandbox, TodoState, TodoUtils } from '@smooved/ui';
import { iif, merge, Observable, of } from 'rxjs';
import { map, switchMap, take, takeLast, takeUntil } from 'rxjs/operators';
import { MeterInfoSuccessComponent } from '../meter-info-success/meter-info-success.component';
import { MeterInfoSuccessState } from '../meter-info-success/meter-info-success.constants';

@Component({
    selector: 'app-admin-meter-info-modal',
    templateUrl: './admin-meter-info.modal.html',
    styleUrls: ['./admin-meter-info.modal.scss'],
})
export class AdminMeterInfoModal extends RxjsComponent implements OnInit {
    public readonly todoStates = TodoState;
    public isSuccess: boolean;
    public originalMove: Move;

    private isRealEstateAgent$ = merge(
        this.authSandbox.isRealEstateAgentOnce$,
        this.authSandbox.isImpersonatedRealEstateAgent$.pipe(take(1))
    ).pipe(take(1));

    private moveDifference$: Observable<[Move, Move]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalDataMove,
        public dialogRef: MatDialogRef<any>,
        private readonly authSandbox: AuthenticationSandbox,
        private readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.originalMove = ObjectUtils.cloneDeep<Move>(this.data.move);

        this.moveDifference$ = this.isRealEstateAgent$.pipe(
            switchMap(this.getMoveWhenRealEstateAgent),
            map((move: Move): [Move, Move] => [move, ObjectUtils.difference(move, this.originalMove)]),
            takeUntil(this.destroy$)
        );

        this.moveDifference$.pipe(takeLast(1)).subscribe(([move, diff]: [Move, Move]) => {
            if (TodoUtils.isChecked(diff.track?.energyMeterReadings.completed)) {
                if (!TodoUtils.isChecked(move.track.leaver.created)) return this.openSuccessModal(MeterInfoSuccessState.NoLeaver);
                return this.openSuccessModal(MeterInfoSuccessState.Completed);
            }
        });
    }

    public handleCloseMeterInfo(result): void {
        this.dialogRef.close(result);
    }

    private getMoveWhenRealEstateAgent = (isRealEstateAgent: boolean): Observable<Move | void> =>
        iif(() => isRealEstateAgent, this.moveSandbox.move$, of());

    private openSuccessModal(state: MeterInfoSuccessState): void {
        const data = { state };
        this.modalSandbox.openDialog({
            componentForMobile: MeterInfoSuccessComponent,
            configForMobile: { data },
            componentForTabletPortraitUp: MeterInfoSuccessComponent,
            configForTabletPortraitUp: { data },
        });
    }
}
