import { energySuppliersLabels } from '@app/energy/constants/energy.constants';
import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';
import { Move } from '@app/move/interfaces/move';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { CheckInput, DropdownInput, UiHeaderVariant } from '@smooved/ui';
import { EnergyStopActiveServices } from '@app/services/energy-stop/enums/energy-stop-active-services';

export interface EnergyStopForm extends Pick<Move, 'movingDate'> {
    energyStop: Partial<EnergyStop>;
}

export const defaultEnergyStop: EnergyStopForm = {
    energyStop: {
        energyStopActiveServices: EnergyStopActiveServices.Both,
        currentGasSupplier: null,
        currentElectricitySupplier: null,
        hasSameSupplier: null,
        invoiceCommunication: null,
    },
};

export const optionElectricity: CheckInput<boolean> = {
    id: 'has-electricity',
    labelResource: 'ELECTRICITY',
    value: true,
    name: 'has-electricity',
    variant: UiHeaderVariant.H6,
};

export const energySuppliers: DropdownInput<EnergySupplier>[] = Object.entries(EnergySupplier).map(([key, value]) => ({
    id: 'energy-supplier',
    labelResource: energySuppliersLabels[value],
    value,
    name: `energy-supplier-${key}`,
}));

export const optionGas: CheckInput<boolean> = {
    id: 'has-gas',
    labelResource: 'GAS',
    value: true,
    name: 'has-gas',
    variant: UiHeaderVariant.H6,
};

export const optionSameSupplier: CheckInput<boolean> = {
    id: 'has-same-supplier',
    labelResource: 'ENERGY.IS_SAME_SUPPLIER',
    value: true,
    name: 'has-same-supplier',
};

export const formControlNames = {
    energyStop: 'energyStop',
    hasGas: 'hasGas',
    hasElectricity: 'hasElectricity',
    hasSameSupplier: 'hasSameSupplier',
    electricityCurrentSupplier: 'currentElectricitySupplier',
    gasCurrentSupplier: 'currentGasSupplier',
    energyStopActiveServices: 'energyStopActiveServices',
};

export const defaultSupplierConfig = {
    [formControlNames.hasElectricity]: true,
    [formControlNames.hasGas]: true,
    [formControlNames.hasSameSupplier]: null,
};

export enum FormControlGroups {
    SupplierControls = 'supplierControls',
    StepCurrentSupplier = 'stepCurrentSupplier',
    StepMeterValues = 'stepMeterValues',
}
