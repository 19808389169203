import { Component, forwardRef, Host, Inject, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import {
    ControlContainer,
    FormBuilder,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';
import { MoveUtils } from '@app/move/state/move.utils';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { AppI18nKeyType } from '@app/shared/constants/i18n-key-type-map';
import { EnergyType } from '@app/wizard/energy/enums/energy-type.enum';
import { DateUtils, I18N_KEY_TYPE_MAP, I18nKeyTypeMap, I18nKeyUtils } from '@smooved/core';
import { BaseInput, ButtonAppearance, ButtonSize, UiContext } from '@smooved/ui';
import { startWith, takeUntil } from 'rxjs/operators';
import {
    defaultEnergyStop,
    formControlNames,
    optionElectricity,
    optionGas,
    optionSameSupplier,
} from './energy-stop-suppliers-form.constants';
import { EnergyStop } from '@app/services/energy-stop/classes/energy-stop';
import { EnergyStopActiveServices } from '@app/services/energy-stop/enums/energy-stop-active-services';

@Component({
    selector: 'app-energy-stop-suppliers-form',
    templateUrl: './energy-stop-suppliers-form.component.html',
    styles: [':host { display: block; }'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EnergyStopSuppliersFormComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EnergyStopSuppliersFormComponent),
            multi: true,
        },
    ],
})
export class EnergyStopSuppliersFormComponent extends BaseInput implements OnInit, Validator {
    @Input() public readonly energyStop: EnergyStop;

    public readonly optionElectricity = optionElectricity;
    public readonly optionGas = optionGas;
    public readonly optionSameSupplier = optionSameSupplier;
    public readonly formControlNames = formControlNames;
    public readonly energyType = EnergyType;
    public readonly today = DateUtils.today();
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly context = UiContext;

    public form: FormGroup;
    public energyStopForm: FormGroup;
    public hasElectricity = new FormControl(true);
    public hasGas = new FormControl(true);
    public hasSameSupplier = new FormControl(null);
    public prefixLabels = '';
    public loading = false;

    private controlForm: FormGroup;

    constructor(
        @Optional() @Host() @SkipSelf() public readonly controlContainer: ControlContainer,
        private readonly fb: FormBuilder,
        private readonly navigationSandbox: AppNavigationSandbox,
        @Inject(I18N_KEY_TYPE_MAP) private readonly i18nKeyTypeMap: I18nKeyTypeMap
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.width = 'auto';
        this.prefixLabels = this.navigationSandbox.isAdminRoute()
            ? `${I18nKeyUtils.map(this.i18nKeyTypeMap, AppI18nKeyType.AdminDashboard, 'DETAIL')}.`
            : '';

        this.form = this.fb.group({
            [formControlNames.energyStop]: this.fb.group({
                [formControlNames.energyStopActiveServices]: [defaultEnergyStop.energyStop.energyStopActiveServices, Validators.required],
                [formControlNames.electricityCurrentSupplier]: [null, Validators.required],
                [formControlNames.gasCurrentSupplier]: [null, Validators.required],
                [formControlNames.hasSameSupplier]: null,
            }),
        });

        this.controlForm = this.fb.group({
            [formControlNames.hasElectricity]: [EnergyType.Electricity, EnergyType.Both].includes(
                this.energyStop?.energyStopActiveServices as unknown as EnergyType
            ),
            [formControlNames.hasGas]: [EnergyType.Gas, EnergyType.Both].includes(
                this.energyStop?.energyStopActiveServices as unknown as EnergyType
            ),
        });
        this.energyStopForm = this.form.get(formControlNames.energyStop) as FormGroup;

        this.hasElectricity = this.controlForm.get(formControlNames.hasElectricity) as FormControl;
        this.hasGas = this.controlForm.get(formControlNames.hasGas) as FormControl;
        this.hasSameSupplier = this.energyStopForm.get(formControlNames.hasSameSupplier) as FormControl;

        this.controlForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: { hasElectricity: boolean; hasGas: boolean }) => {
            this.hasElectricity.value
                ? this.energyStopForm.get(formControlNames.electricityCurrentSupplier).addValidators(Validators.required)
                : this.energyStopForm.get(formControlNames.electricityCurrentSupplier).clearValidators();
            this.hasGas.value
                ? this.energyStopForm.get(formControlNames.gasCurrentSupplier).addValidators(Validators.required)
                : this.energyStopForm.get(formControlNames.gasCurrentSupplier).clearValidators();
            this.form.updateValueAndValidity();
        });

        if (this.form.enabled) {
            this.hasSameSupplier.valueChanges
                .pipe(startWith(this.hasSameSupplier.value), takeUntil(this.destroy$))
                .subscribe(this.setSameSupplier);
            this.hasElectricity.valueChanges
                .pipe(startWith(this.hasElectricity.value), takeUntil(this.destroy$))
                .subscribe(this.resetElectricity);
            this.hasGas.valueChanges.pipe(startWith(this.hasGas.value), takeUntil(this.destroy$)).subscribe(this.resetGas);

            this.energyStopForm
                .get(formControlNames.electricityCurrentSupplier)
                .valueChanges.pipe(takeUntil(this.destroy$))
                .subscribe(this.handleElectricityChanged);
        }

        this.formControl.markAsTouched = (): void => {
            this.form.markAllAsTouched();
        };

        this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleFormValueChanged(this.form.getRawValue()));
    }

    public writeValue(energyStop: EnergyStop): void {
        if (!energyStop) return;
        const input = {
            ...defaultEnergyStop,
            energyStop: {
                ...defaultEnergyStop.energyStop,
                energyType: energyStop.energyStopActiveServices || EnergyStopActiveServices.Both, // Set default as Both if energyType not defined,
                currentElectricitySupplier: energyStop.supplierElectricity,
                currentGasSupplier: energyStop.supplierGas,
                hasSameSupplier: energyStop.supplierElectricity === energyStop.supplierGas,
            },
        };

        const { energyStop: value } = input;
        this.hasElectricity.setValue(MoveUtils.isElectricityEnergyType(value.energyType as unknown as EnergyType));
        this.hasGas.setValue(MoveUtils.isGasEnergyType(value.energyType as unknown as EnergyType));
        this.setEnergyType();
        this.hasSameSupplier.setValue(value.hasSameSupplier);

        this.form.patchValue(input);
    }

    public setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
        isDisabled ? this.controlForm.disable({ emitEvent: false }) : this.controlForm.enable({ emitEvent: false });
    }

    public validate(): ValidationErrors | null {
        return this.form.invalid ? { suppliers: true } : null;
    }

    private setSameSupplier = (sameSupplier: boolean): void => {
        let currentSupplier: EnergySupplier = null;

        if (sameSupplier) {
            currentSupplier = this.energyStopForm.get(formControlNames.electricityCurrentSupplier).value as EnergySupplier;

            this.energyStopForm.patchValue({
                [formControlNames.gasCurrentSupplier]: currentSupplier,
            });
        }

        this.form.updateValueAndValidity();
    };

    private resetElectricity = (isSet): void => {
        if (!isSet) {
            this.clearElectricity();
            this.hasSameSupplier.setValue(false);
            this.hasGas.disable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.electricityCurrentSupplier).disable();
        } else {
            this.hasGas.enable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.electricityCurrentSupplier).enable();
        }
        this.setEnergyType();
        this.form.updateValueAndValidity();
    };

    private resetGas = (isSet): void => {
        if (!isSet) {
            this.clearGas();
            this.hasSameSupplier.setValue(false);
            this.hasElectricity.disable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.gasCurrentSupplier).disable();
        } else {
            this.hasElectricity.enable({ emitEvent: false });
            this.energyStopForm.get(formControlNames.gasCurrentSupplier).enable();
        }
        this.setEnergyType();
        this.form.updateValueAndValidity();
    };

    private clearGas(): void {
        this.energyStopForm.patchValue({
            [formControlNames.gasCurrentSupplier]: null,
        });
    }

    private clearElectricity(): void {
        this.energyStopForm.patchValue({
            [formControlNames.electricityCurrentSupplier]: null,
        });
    }

    private setEnergyType(): void {
        let energyType: EnergyType;
        if (this.hasElectricity.value && this.hasGas.value) energyType = EnergyType.Both;
        else if (this.hasElectricity.value) energyType = EnergyType.Electricity;
        else if (this.hasGas.value) energyType = EnergyType.Gas;
        this.energyStopForm.get(formControlNames.energyStopActiveServices).setValue(energyType);
    }

    private handleElectricityChanged = (_: any): void => {
        const currentSupplier = this.energyStopForm.get(formControlNames.electricityCurrentSupplier).value as string;

        if (this.hasSameSupplier.value) {
            this.energyStopForm.patchValue({
                [formControlNames.gasCurrentSupplier]: currentSupplier,
            });
        }
    };

    private handleFormValueChanged = (value: { energyStop: EnergyStop }): void => this.propagateChange(value);
}
